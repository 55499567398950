import Todo from "./Todo";

function App() {
    return (
    <>
        <Todo />
    </>
  );
}

export default App;
